export const Mail_Vars = {
    fb_link: "https://www.facebook.com/",
    tw_link: "https://twitter.com/",
    sitename: "Black & Blanc Estate Agents",
    contact_email: "hello@blackandblanc.co.uk",
    company_phone: "0203 880 7770",
    available_time: "9am - 5pm ,",
    available_days: " Monday - Friday",
    address: "107 High Street, West Wickham, England BR4 0LT, GB",
    primary_color: "#000000"
}

export const Site_Vars = {
    default_currency: "£"
}

export const getWhatsAppURL = number => {
    if (!number) return null
    // remove everything except numbers
    const formatedNumber = number?.replace(/\D/g, "")
    return `https://api.whatsapp.com/send?phone=${formatedNumber}`
}